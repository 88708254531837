/**
 * @method GET
 * @description title, description, partner logo, SEO на главной странице
 * */

export interface IApiContentWelcomeItem {
  title: string
  description: string
  logos: Array<{
    id: string
    path: string
    sort: number
    url: string
  }>
}

export interface IApiContentSeoItem {
  title?: string
  text: string
}

export default async function apiContentMainPage () {
  const endpoint = `/content/v1/home-page-builder`

  return await useAsyncQuery(async ({ axios, path }) => {
    return await axios.get(path + endpoint)
  })
}
