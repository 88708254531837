<template>
  <div data-testid="element_000286">
    <ui-banner class="mt2-12 mt2-0_table" :page="'home'" />

    <pages-main-welcome :welcome-data="welcomeData" class="mt2-40 mt2-24_table" />

    <ui-card-main-list
      v-for="category in homeCategories"
      :key="category.category"
      :category="category"
      tag="h2"
      class="mt2-40 mt2-24_table"
    />

    <ui-banner-small-banner class="mt2-40 mt2-24_mobile" page="main-page" type="bottom" />

    <ui-info-block :entity="SEO_ENTITY_PAGE_TYPE.home" class="mt2-40 mb2-40 mb2-24_table" />
  </div>
</template>

<script lang="ts" setup>
import apiContentMainPage, { type IApiContentWelcomeItem } from '~/api/content/mainPage'
import apiContentVideosHome, { type IApiContentVideosHomeResponse } from '~/api/content/videos-home'
import { type IApiContentVideoItem } from '~/api/content/videos'
import { type ISkeletonItem } from '~/shared/types/composables/composables'
import { SEO_ENTITY_PAGE_TYPE } from '~/shared/constants/seo'
import useCanonicalUrl from "~/composables/useCanonicalUrl"
import { MAIN_PAGE_VIDEOS } from "~/shared/constants/main-page-videos";

const seo = useSeoModule()

const contentMainPageQuery = useAsyncData('homepage-content', async () => {
  return await apiContentMainPage()
})

const welcomeData = computed<IApiContentWelcomeItem>(() => {
  return {
    title: seo.heading.value,
    description: contentMainPageQuery.data.value?.data?.item?.descriptionArea || '',
    logos: contentMainPageQuery.data.value?.data?.item?.partnerLogos || '',
  }
})

const homeCategories = computed(() => {
  const videos = MAIN_PAGE_VIDEOS

  const prepareVideos = (videos: IApiContentVideoItem[] | undefined) => {
    return videos
  }

  const categories = []
  if (videos?.new?.length) {
    const list = prepareVideos(videos?.new)
    categories.push({
      seoType: 'new',
      category: 'New',
      link: {
        to: '/new/',
        title: 'View more',
      },
      list,
    })
  }
  if (videos?.hotRightNow?.length) {
    categories.push({
      seoType: 'hot_right_now',
      category: 'Hot Right Now',
      link: {
        to: '/all/',
        title: 'View more',
      },
      list: prepareVideos(videos?.hotRightNow),
    })
  }
  if (videos?.popular?.length) {
    categories.push({
      seoType: 'popular',
      category: 'Popular',
      link: {
        to: '/all/',
        title: 'View more',
      },
      list: prepareVideos(videos?.popular),
    })
  }
  if (videos?.game?.length) {
    categories.push({
      seoType: 'game',
      category: 'Games',
      link: {
        to: '/games/',
        title: 'View more',
      },
      list: prepareVideos(videos?.game),
    })
  }

  return categories
})

const seoList = {
  new: homeCategories.value.find((item) => item.seoType === 'new')?.list || [],
  hot_right_now: homeCategories.value.find((item) => item.seoType === 'hot_right_now')?.list || [],
  popular: homeCategories.value.find((item) => item.seoType === 'popular')?.list || [],
  game: homeCategories.value.find((item) => item.seoType === 'game')?.list || [],
}

const config = seo.defineSeoPage(SEO_ENTITY_PAGE_TYPE.home, {
  canonicalPath: useCanonicalUrl(),
  data: {
    entity_breadcrumb: 'Home',
  },
})

seo.setTemplateDataByKey('main_new_videos_block', config.page?.blocksJsonLd[0])
seo.setTemplateDataByKey('main_hot_videos_block', config.page?.blocksJsonLd[1])
seo.setTemplateDataByKey('main_popular_videos_block', config.page?.blocksJsonLd[2])
seo.setTemplateDataByKey('main_games_block', config.page?.blocksJsonLd[3])

seo.generateCompositeContextByItems('main_new_videos_block', seoList.new)
seo.generateCompositeContextByItems('main_hot_videos_block', seoList.hot_right_now)
seo.generateCompositeContextByItems('main_popular_videos_block', seoList.popular)
seo.generateCompositeContextByItems('main_games_block', seoList.game)

seo.applySeoForPage(config)
</script>
